/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CheckoutPayloadDto } from '../models/checkout-payload-dto';
import { CheckoutSessionResponseDto } from '../models/checkout-session-response-dto';
import { ProductCheckoutPayloadDto } from '../models/product-checkout-payload-dto';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation stripeCheckoutControllerCreateLicenseCheckout
   */
  static readonly StripeCheckoutControllerCreateLicenseCheckoutPath = '/api/checkout/license';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stripeCheckoutControllerCreateLicenseCheckout()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  stripeCheckoutControllerCreateLicenseCheckout$Response(params: {
    context?: HttpContext;
    body: CheckoutPayloadDto;
  }): Observable<StrictHttpResponse<CheckoutSessionResponseDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      CheckoutService.StripeCheckoutControllerCreateLicenseCheckoutPath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CheckoutSessionResponseDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stripeCheckoutControllerCreateLicenseCheckout$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  stripeCheckoutControllerCreateLicenseCheckout(params: {
    context?: HttpContext;
    body: CheckoutPayloadDto;
  }): Observable<CheckoutSessionResponseDto> {
    return this.stripeCheckoutControllerCreateLicenseCheckout$Response(params).pipe(
      map((r: StrictHttpResponse<CheckoutSessionResponseDto>) => r.body as CheckoutSessionResponseDto)
    );
  }

  /**
   * Path part for operation stripeCheckoutControllerCreateProductCheckout
   */
  static readonly StripeCheckoutControllerCreateProductCheckoutPath = '/api/checkout/product';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stripeCheckoutControllerCreateProductCheckout()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  stripeCheckoutControllerCreateProductCheckout$Response(params: {
    context?: HttpContext;
    body: ProductCheckoutPayloadDto;
  }): Observable<StrictHttpResponse<CheckoutSessionResponseDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      CheckoutService.StripeCheckoutControllerCreateProductCheckoutPath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CheckoutSessionResponseDto>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stripeCheckoutControllerCreateProductCheckout$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  stripeCheckoutControllerCreateProductCheckout(params: {
    context?: HttpContext;
    body: ProductCheckoutPayloadDto;
  }): Observable<CheckoutSessionResponseDto> {
    return this.stripeCheckoutControllerCreateProductCheckout$Response(params).pipe(
      map((r: StrictHttpResponse<CheckoutSessionResponseDto>) => r.body as CheckoutSessionResponseDto)
    );
  }

  /**
   * Path part for operation stripeCheckoutControllerRead
   */
  static readonly StripeCheckoutControllerReadPath = '/api/checkout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stripeCheckoutControllerRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  stripeCheckoutControllerRead$Response(params?: { context?: HttpContext }): Observable<StrictHttpResponse<Array<{}>>> {
    const rb = new RequestBuilder(this.rootUrl, CheckoutService.StripeCheckoutControllerReadPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<{}>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stripeCheckoutControllerRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stripeCheckoutControllerRead(params?: { context?: HttpContext }): Observable<Array<{}>> {
    return this.stripeCheckoutControllerRead$Response(params).pipe(
      map((r: StrictHttpResponse<Array<{}>>) => r.body as Array<{}>)
    );
  }
}
