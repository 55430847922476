import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppMessagesService } from '@kate-fizjo/practice-shared/message';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import * as actions from './owner.actions';
import { fetchOwnerData, ownerActions } from './owner.actions';
import { OwnerDto } from '../api/models';
import { OwnerService } from '../api/services/owner.service';

@Injectable()
export class OwnerEffects {
  #actions$: Actions = inject(Actions);
  #ownerService: OwnerService = inject(OwnerService);
  #router: Router = inject(Router);
  #appMessages: AppMessagesService = inject(AppMessagesService);

  public createOwner$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(actions.createOwner),
      switchMap(action =>
        this.#ownerService.ownerControllerCreate({
          body: action.payload,
        })
      ),
      map((owner: OwnerDto) => actions.createOwnerSuccess({ owner }))
    );
  });
  public createOwnerWithAnimal$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(actions.createOwnerWithAnimal),
      switchMap(action =>
        this.#ownerService.ownerControllerCreate({
          body: action.payload,
        })
      ),
      map((owner: OwnerDto) => actions.createOwnerWithAnimalSuccess({ owner }))
    );
  });
  public createOwnerWithAnimalSuccess$ = createEffect(
    () => {
      return this.#actions$.pipe(
        ofType(actions.createOwnerWithAnimalSuccess),
        switchMap(action =>
          this.#router.navigate(['/patients/create'], {
            queryParams: {
              firstName: action.owner.firstname,
              lastName: action.owner.lastname,
              ownerId: action.owner._id,
            },
          })
        ),
        tap(() => {
          this.#appMessages.addMessage({
            detail: 'Zapisano właściciela',
            severity: 'success',
            summary: 'Zapisywanie właściciela',
          });
        })
      );
    },
    { dispatch: false }
  );
  public createOwnerSuccess$ = createEffect(
    () => {
      return this.#actions$.pipe(
        ofType(actions.createOwnerSuccess, actions.updateOwnerSuccess),
        switchMap(action => this.#router.navigate(action.path || ['/owners', action.owner._id])),
        tap(() => {
          this.#appMessages.addMessage({
            detail: 'Zapisano właściciela',
            severity: 'success',
            summary: 'Zapisywanie właściciela',
          });
        })
      );
    },
    { dispatch: false }
  );
  public fetchOwners$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(actions.fetchOwners),
      switchMap(() => this.#ownerService.ownerControllerReadAll()),
      map((owners: OwnerDto[]) => actions.fetchOwnersSuccess({ owners }))
    );
  });
  public updateOwner$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(actions.updateOwner),
      switchMap(action =>
        this.#ownerService.ownerControllerUpdate({
          body: action.payload,
          ownerId: action.ownerId,
        })
      ),
      map((owner: OwnerDto) => actions.updateOwnerSuccess({ owner }))
    );
  });
  public fetchOwnerData$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fetchOwnerData),
      switchMap(action =>
        this.#ownerService.ownerControllerReadOne({
          ownerId: action.ownerId,
        })
      ),
      map((owner: OwnerDto) => actions.fetchOwnerDataSuccess({ owner }))
    );
  });
  public createInvitation$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(ownerActions.createInvitation),
      switchMap(action =>
        this.#ownerService.ownerControllerCreateInvitation({
          ownerId: action.ownerId,
          channel: action.channel,
        })
      ),
      map(() => ownerActions.createInvitationSuccess())
    );
  });
  public createInvitationSuccess$ = createEffect(
    () => {
      return this.#actions$.pipe(
        ofType(ownerActions.createInvitationSuccess),
        tap(() => {
          this.#appMessages.addMessage({
            detail: 'Pomyślnie wysłano zaproszenie do aplikacji Fizjo.pro',
            severity: 'success',
            summary: 'Zaproszenie do aplikacji',
          });
        })
      );
    },
    { dispatch: false }
  );
  public sendGdprRequest$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(ownerActions.sendGdprRequest),
      switchMap(action =>
        this.#ownerService.ownerControllerRequestGdpr({
          ownerId: action.ownerId,
        })
      ),
      map(() => ownerActions.sendGdprRequestSuccess())
    );
  });
  public sendGdprRequestSuccess$ = createEffect(
    () => {
      return this.#actions$.pipe(
        ofType(ownerActions.sendGdprRequestSuccess),
        tap(() => {
          this.#appMessages.addMessage({
            detail: 'Pomyślnie wysłano zaproszenie do zaakceptowania regulaminu Rodo',
            severity: 'success',
            summary: 'Rodo',
          });
        })
      );
    },
    { dispatch: false }
  );
  public sendMessage$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(ownerActions.sendMessage),
      switchMap(action =>
        this.#ownerService.ownerControllerSendMessage({
          body: { content: action.content },
          channel: action.channel,
          ownerId: action.ownerId,
        })
      ),
      map(() => ownerActions.sendMessageSuccess())
    );
  });
  public sendMessageSuccess$ = createEffect(
    () => {
      return this.#actions$.pipe(
        ofType(ownerActions.sendMessageSuccess),
        tap(() => {
          this.#appMessages.addMessage({
            detail: 'Pomyślnie wysłano wiadomość',
            severity: 'success',
            summary: 'Wiadomość',
          });
        })
      );
    },
    { dispatch: false }
  );
}
