import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime, Duration } from 'luxon';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { FloatLabelModule } from 'primeng/floatlabel';
import { map, Observable } from 'rxjs';

import { DateRange, timeRangeForm, TimeRangeForm } from './time-range.form';

@Component({
  selector: 'fizjo-pro-time-range',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CalendarModule,
    SharedModule,
    TranslateModule,
    FloatLabelModule,
    ReactiveFormsModule,
  ],
  templateUrl: './time-range.component.html',
  styleUrl: './time-range.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeRangeComponent),
      multi: true,
    },
  ],
})
export class TimeRangeComponent implements ControlValueAccessor, OnInit {
  #changeCallback!: (range: DateRange) => void;
  @Output() hide: EventEmitter<void> = new EventEmitter<void>();
  duration$!: Observable<string>;
  timeRangeForm: FormGroup<TimeRangeForm> = timeRangeForm();

  ngOnInit() {
    this.duration$ = this.timeRangeForm.valueChanges.pipe(
      map(range => {
        if (range.to && range.from) {
          const duration: Duration = DateTime.fromJSDate(range.to).diff(DateTime.fromJSDate(range.from));

          return duration
            .rescale()
            .set({ milliseconds: 0, second: 0 })
            .normalize()
            .toHuman({ useGrouping: true, listStyle: 'long', compactDisplay: 'long' });
        }

        return '';
      })
    );

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.timeRangeForm.controls.date.valueChanges.subscribe(date => {
      const from: DateTime = DateTime.fromJSDate(this.timeRangeForm.controls.from.getRawValue());
      const to: DateTime = DateTime.fromJSDate(this.timeRangeForm.controls.to.getRawValue());
      const dt: DateTime = DateTime.fromJSDate(date);

      this.timeRangeForm.controls.from.setValue(from.set({ year: dt.year, month: dt.month, day: dt.day }).toJSDate());
      this.timeRangeForm.controls.to.setValue(to.set({ year: dt.year, month: dt.month, day: dt.day }).toJSDate());
    });
  }

  registerOnChange(callback: (range: DateRange) => void): void {
    this.#changeCallback = callback;
  }

  registerOnTouched(callback: () => void): void {
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.timeRangeForm.valueChanges.subscribe(() => callback());
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      ['date', 'start', 'end'].forEach(key => this.timeRangeForm.get(key)?.disable());
    } else {
      ['date', 'start', 'end'].forEach(key => this.timeRangeForm.get(key)?.enable());
    }
  }

  writeValue(dateRange: DateRange | null): void {
    console.log(dateRange);
    if (dateRange) {
      this.timeRangeForm.setValue(
        {
          date: DateTime.fromJSDate(dateRange.from).toJSDate(),
          from: DateTime.fromJSDate(dateRange.from).startOf('minute').toJSDate(),
          to: DateTime.fromJSDate(dateRange.to).startOf('minute').toJSDate(),
        },
        { emitEvent: false }
      );
    }
  }
  emitValue(): void {
    if (this.timeRangeForm.valid) {
      const dateRange = this.timeRangeForm.getRawValue();

      this.hide.emit();
      this.#changeCallback({ from: dateRange.from as Date, to: dateRange.to as Date });
    }
  }
}
