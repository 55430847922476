<div class="container line-height-3 my-6">
  <p-card header="Regulamin Usługi fizjo.pro">
    <p>Obowiązuje od: ………………………</p>
    <p>
      Niniejszy regulamin określa warunki świadczenia przez Operatora usług za pośrednictwem Aplikacji fizjo.pro na
      rzecz Klienta
    </p>
    <ol class="p-0">
      <li>
        <strong>1.&nbsp;Definicje</strong>
        <ol>
          <li>
            <strong>1.1.&nbsp;Operator (Operator Aplikacji)</strong> – ANIMALWARE.PRO Sp. z o.o. z siedzibą w Warszawie,
            pod adresem ul. Aleja Jana Pawła II, nr 27, 00-867 Warszawa, wpisany do rejestru przedsiębiorców
            prowadzonego przez Sąd Rejonowy dla m.st. Warszawy, XIII Wydział Gospodarczy pod numerem KRS: 0001111201,
            REGON: 528918717, NIP: 5273116833;
          </li>
          <li>
            <strong>1.2.&nbsp;Klient</strong> – podmiot prowadzący działalność gospodarczą, który zdecydował o
            rejestracji Konta Klienta i korzystaniu z Aplikacji przez wyznaczone osoby fizyczne, jako Użytkowników
            Aplikacji.
          </li>
          <li>
            <strong>1.3.&nbsp;Aplikacja</strong> – aplikacja internetowa o nazwie fizjo.pro udostępniana przez Operatora
            pod adresem https://[domena-klienta].fizjo.pro oraz
            <a href="https://mypet.fizjo.pro" target="_blank">https://mypet.fizjo.pro</a>, umożliwiająca korzystanie z
            funkcjonalności opisanych w Regulaminie;
          </li>
          <li>
            <strong>1.4.&nbsp;Użytkownik</strong> – Klient lub osoba fizyczna, którą Klient upoważnił do korzystania z
            Aplikacji w ramach Konta Klienta, wskutek rejestracji Konta Użytkownika a następnie jego aktywacji dokonanej
            przez tę osobę;
          </li>
          <li>
            <strong>1.5.&nbsp;Strona Internetowa</strong> – strona internetowa pod adresem
            <a href="https://fizjo.pro" target="_blank">https://fizjo.pro</a>, na której Operator publikuje informacje
            obowiązujące Klientów i Użytkowników oraz inne treści mogące stanowić wartość dla Klientów i Użytkowników.
          </li>
          <li>
            <strong>1.6.&nbsp;Właściciel Licencji</strong> – rola administrowania Kontem Klienta, realizowana przez
            Klienta lub powierzona do realizacji osobie fizycznej działającej w imieniu i na rzecz Klienta, działającej
            jako Użytkownik Aplikacji, za pomocą której następuje obsługa wszystkich udostępnionych w Aplikacji
            funkcjonalności Konta Klienta, w tym wyznaczenie osób fizycznych mających zostać Użytkownikami, wybór
            Licencji czy zakończenie Umowy.
          </li>
          <li>
            <strong>1.7.&nbsp;Login</strong> – indywidualny i niepowtarzalny adres poczty elektronicznej będący
            unikalnym identyfikatorem Użytkownika w Aplikacji;
          </li>
          <li>
            <strong>1.8.&nbsp;Hasło</strong> – ciąg znaków określony przez Użytkownika i przypisany Użytkownikowi,
            wymagane do zalogowania się do Aplikacji przez Użytkownika;
          </li>
          <li>
            <strong>1.9.&nbsp;Konto Klienta</strong> – zbiór ustawień w Aplikacji na rzecz Klienta, w ramach
            funkcjonalności Aplikacji w wybranej przez Klienta Licencji jako Usługi świadczonej przez Operatora za
            pośrednictwem Aplikacji.
          </li>
          <li>
            <strong>1.10.&nbsp;Konto Użytkownika</strong> – zbiór ustawień w Aplikacji na rzecz Użytkownika, chronionych
            Loginem i Hasłem, w ramach funkcjonalności Aplikacji w wybranej przez Klienta Licencji.
          </li>
          <li>
            <strong>1.11.&nbsp;Domena klienta</strong> - indywidualna, unikalna subdomena w domenie fizjo.pro, określona
            przez Właściciela licencji podczas zakładania konta, pod którą świadczona jest usługa dostępu do Aplikacji
            fizjo.pro dla Klienta.
          </li>
          <li>
            <strong>1.12.&nbsp;Ustawienia Konta Klienta</strong> – podstrona Aplikacji znajdująca się w sekcji
            “Ustawienia”, na której wyszczególnione są dla Właściciela Licencji wybrane i obowiązujące:
            <ol>
              <li><strong>1.12.1.&nbsp;</strong>Licencja,</li>
              <li><strong>1.12.2.&nbsp;</strong>wysokość Opłaty,</li>
              <li><strong>1.12.3.&nbsp;</strong>dzień rozpoczęcia i zakończenia Okresu Abonamentowego.</li>
            </ol>
          </li>
          <li>
            <strong>1.13.&nbsp;Cennik</strong> – oferta Operatora przedstawiającą porównanie świadczeń i cen w obrębie
            Licencji do korzystania z Aplikacji, dostępna w Aplikacji
          </li>
          <li>
            <strong>1.14.&nbsp;Licencja</strong> – pakiet świadczeń zapewnianych w ramach Usługi przez Operatora w
            Okresie Abonamentowym oraz dodatkowe wybrane przez Klienta rozszerzenia Usługi.
          </li>
          <li>
            <strong>1.15.&nbsp;Okres Abonamentowy</strong> – miesięczny okres funkcjonowania Konta Klienta i dostępności
            Aplikacji w odpowiednim zakresie, w ramach którego uiszczana jest Opłata, zgodnie z wybraną Licencją.
          </li>
          <li>
            <strong>1.16.&nbsp;Opłata</strong> – opłata z tytułu utrzymania Konta Klienta w Aplikacji i dostępu do jego
            funkcjonalności zgodnie z zakresem Licencji.
          </li>
          <li>
            <strong>1.17.&nbsp;</strong> Próbny – okres testowego korzystania z Aplikacji przez 30 dni od dnia
            zakończenia rejestracji Konta Klienta, w którym korzystanie z Aplikacji nie wymaga opłaty.
          </li>
          <li>
            <strong>1.18.&nbsp;Usługa</strong> – świadczenie przez Operatora udostępniania oprogramowania Aplikacji w
            ramach wybranej Licencji, na warunkach określonych w Regulaminie.
          </li>
          <li>
            <strong>1.19.&nbsp;Umowa</strong> – umowa o świadczenie usług drogą elektroniczną, do zawarcia której
            dochodzi między Operatorem a Klientem wskutek akceptacji Regulaminu, w odniesieniu do funkcjonowania Konta
            Klienta w Aplikacji i korzystania z Aplikacji przez Użytkowników w zakresie wybranej Licencji, obejmująca
            prawa i obowiązki stron w tym względzie.
          </li>
          <li>
            <strong>1.20.&nbsp;Regulamin</strong> – niniejszy dokument, który stanowi regulamin w rozumieniu art. 8
            ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną;
          </li>
          <li>
            <strong>1.21.&nbsp;Polityka prywatności</strong> – polityka określająca zasady przetwarzania danych
            osobowych Użytkowników Aplikacji oraz plików cookie.
          </li>
        </ol>
      </li>
      <li>
        <strong>2.&nbsp;Postanowienia ogólne</strong>
        <ol>
          <li>
            <strong>2.1.&nbsp;</strong>Regulamin określa zasady udostępnienia Aplikacji przez Operatora aplikacji
            fizjo.pro oraz obowiązki i prawa Użytkowników Aplikacji.
          </li>
          <li>
            <strong>2.2.&nbsp;</strong>Warunkiem korzystania z Usług świadczonych przez Operatora Klientowi i
            Użytkownikom za pośrednictwem Aplikacji, jest zapoznanie się z treścią Regulaminu oraz jego akceptacja w
            imieniu Klienta przez Właściciela Licencji oraz przez każdego z Użytkowników w ramach procesu aktywacji
            Konta Użytkownika.
          </li>
          <li>
            <strong>2.3.&nbsp;</strong>W przypadku wątpliwości wobec treści Regulaminu, należy skontaktować się z
            Operatorem mailowo pod adresem: <a href="mailto:kontakt@fizjo.pro">kontakt&commat;fizjo.pro</a>.
          </li>
          <li>
            <strong>2.4.&nbsp;</strong>Każda zmiana Regulaminu wymaga ponownej jego akceptacji przez Użytkownika
            Aplikacji przy uruchomieniu Aplikacji.
          </li>
          <li>
            <strong>2.5.&nbsp;</strong>Z wykorzystaniem Aplikacji, Operator świadczy Usługę w obrębie Konta Klienta na
            zasadach wskazanych w Regulaminie, umożliwiając przede wszystkim:
            <ol>
              <li><strong>2.5.1.&nbsp;</strong>Zarządzanie dokumentacją dotyczącą procesu rehabilitacji zwierząt;</li>
              <li><strong>2.5.2.&nbsp;</strong>Komunikację z właścicielami zwierząt;</li>
              <li>
                <strong>2.5.3.&nbsp;</strong>Udostępnianie informacji właścicielom zwierząt oraz innym podmiotom
                uczestniczącym w procesie terapeutycznym
              </li>
              <li><strong>2.5.4.&nbsp;</strong>Organizację czasu pracy w gabinecie zoofizjoterapeutycznym</li>
              <li>
                <strong>2.5.5.&nbsp;</strong>
                Archiwizację danych historycznych dotyczących procesu rehabilitacji zwierząt poprzez Aplikację, w celach
                raportowych dla Klienta oraz w celach prowadzenia prac badawczo-rozwojowych nad procesami rehabilitacji
                zwierząt przez Operatora;
              </li>
            </ol>
          </li>
          <li>
            <strong>2.6.&nbsp;</strong>
            Operator zastrzega sobie prawo do czasowego wyłączenia wybranych funkcjonalności Aplikacji z przyczyn
            uzasadnionych prawnie lub technicznie, o czym Klient zostanie niezwłocznie poinformowany poprzez Aplikację.
          </li>
          <li>
            <strong>2.7.&nbsp;</strong>Minimalne wymagania techniczne systemu komputerowego, z którego Użytkownik chce
            korzystać w ramach dostępu do Usług świadczonych poprzez Aplikację: Najnowsza, aktualna wersja przeglądarki
            Chrome, Firefox z włączoną obsługą JavaScript oraz plikami Cookie Połączenie z siecią Internet.
          </li>
          <li>
            <strong>2.8.&nbsp;</strong>Operator zastrzega sobie prawo do modyfikowania technicznego sposobu realizacji
            Usług, stosownie do rozwijania możliwości technicznych i osiąganych postępów w ulepszaniu Usługi.
          </li>
          <li>
            <strong>2.9.&nbsp;</strong>W celu zapewnienia bezpieczeństwa przekazu komunikatów i w związku ze
            świadczonymi Usługami, Operator podejmuje środki techniczne i organizacyjne odpowiednie do stopnia
            zagrożenia bezpieczeństwa świadczonych Usługi drogą elektroniczną.
          </li>
        </ol>
      </li>
      <li>
        <strong>3.&nbsp;Poszanowanie własności intelektualnej</strong>
        <ol>
          <li>
            <strong>3.1.&nbsp;</strong>Poszanowanie własności intelektualnejOperator oświadcza, że Aplikacja jest
            chroniona prawami autorskimi, a oprogramowanie to jest wolne wad fizycznych i prawnych. Operator zapewnia,
            że jest uprawniony do świadczenia Usług wskazanych w Regulaminie, a korzystanie z Aplikacji zgodnie z
            niniejszym Regulaminem, nie będzie stanowić naruszenia praw osób trzecich.
          </li>
          <li>
            <strong>3.2.&nbsp;</strong>Aplikacja oraz zawarte w niej elementy graficzne, logotypy, spersonalizowane
            emblematy (zwane łącznie: „Znakami”) stanowią znaki towarowe.
          </li>
          <li>
            <strong>3.3.&nbsp;</strong>
            Znaki, rozwiązania nawigacyjne Aplikacji, wybór i układ prezentowanych w ramach Aplikacji treści stanowią
            przedmiot praw objętych ochroną, stąd wszelkie ich wykorzystywanie w sposób inny niż korzystanie z Aplikacji
            w ramach udzielonego przez Operatora dostępu, jest niedozwolone.
          </li>
          <li>
            <strong>3.4.&nbsp;</strong>
            Zakazane jest używanie i kopiowanie oprogramowania, procesów i technologii, stanowiących część aplikacji.
          </li>
          <li>
            <strong>3.5.&nbsp;</strong>Operator zastrzega sobie prawo do przetwarzania zanonimizowanych danych
            dotyczących terapii zwierząt w celach doskonalenia produktu, prowadzenia prac badawczo-rozwojowych, analizy
            z wykorzystaniem sztucznej inteligencji, w tym analizowania opisów wizyt, diagnoz i prowadzonej terapii, w
            celu doskonalenia świadczonych usług.
          </li>
        </ol>
      </li>
      <li>
        <strong>4.&nbsp;Rejestracja i konfiguracja Konta klienta</strong>
        <ol>
          <li>
            <strong>4.1.&nbsp;</strong>
            Dostęp do Usług możliwy jest wyłącznie po samodzielnym założeniu Konta Klienta w Aplikacji przez Właściciela
            Licencji na Stronie Internetowej - https://fizjo.pro/register, a przez to udostępnianiu Operatorowi
            Aplikacji identyfikujących go danych (w tym danych osobowych) w ramach Aplikacji oraz w sposób opisany w
            niniejszym rozdziale Regulaminu, a po upływie Okresu Próbnego, także pod warunkiem uiszczania Opłaty
            stosownie do wybranej Licencji.
          </li>
          <li>
            <strong>4.2.&nbsp;</strong>Utworzenie Konta Klienta rozpoczyna się od wypełnienia i przesłania przez Klienta
            formularza rejestracyjnego udostępnianego na stronie https://fizjo.pro/register oraz wymaga podania
            następujących danych:
            <ol>
              <li><strong>4.2.1.&nbsp;</strong>imienia i nazwiska Właściciela Licencji,</li>
              <li><strong>4.2.2.&nbsp;</strong>nazwy firmy Klienta,</li>
              <li><strong>4.2.3.&nbsp;</strong>adresu poczty elektronicznej Właściciela Licencji,</li>
              <li><strong>4.2.4.&nbsp;</strong>numeru telefonu do kontaktu oraz nazwy</li>
              <li>
                <strong>4.2.5.&nbsp;</strong>indywidualnej domeny utworzonej przez Klienta
                (https://[domena-klienta].fizjo.pro).
              </li>
            </ol>
          </li>
          <li>
            <strong>4.3.&nbsp;</strong>
            Właściciel Licencji otrzymuje na podany numer telefonu wiadomość sms z tymczasowym Hasłem oraz e-mail
            potwierdzający założenie konta. Przy pierwszym logowaniu Aplikacja wymusza zmianę Hasła dostępu dla
            Właściciela Licencji oraz ponowne zaakceptowanie Regulaminu Aplikacji.
          </li>
          <li>
            <strong>4.4.&nbsp;</strong>Utworzenie konta rozpoczyna Okres Próbny, w którym Właściciel Licencji może
            korzystać testowo z Aplikacji, w ramach przydzielonych zasobów.
          </li>
          <li>
            <strong>4.5.&nbsp;</strong>
            Po upływie Okresu Próbnego, dalsze korzystanie z Konta jest uzależnione od podania (najpóźniej do ostatniego
            dnia Okresu Próbnego):
            <ol>
              <li><strong>4.5.1.&nbsp;</strong>wybranej Licencji;</li>
              <li>
                <strong>4.5.2.&nbsp;</strong>danych, niezbędnych do rozliczeń z Operatorem, tj.: do wystawienia faktury;
              </li>
              <li>
                <strong>4.5.3.&nbsp;</strong>
                zgody na przesyłanie faktur VAT za pomocą elektronicznych środków komunikacji (w formacie PDF) oraz
                adres e-mailu Klienta do wysyłania faktur;
              </li>
              <li>
                <strong>4.5.4.&nbsp;</strong>wniesienia stosownej opłaty, wynikającej z wybranych produktów i
                obowiązującego cennika.
              </li>
            </ol>
          </li>
          <li>
            <strong>4.6.&nbsp;</strong>
            Właściciel Licencji zobowiązany jest do podania danych zgodnych z prawdą, dokładnych i aktualnych,
            niewprowadzających w błąd oraz nienaruszających praw osób trzecich, a w każdym przypadku ich zmiany w czasie
            korzystania z Aplikacji do ich aktualizowania poprzez formularz w sekcji Ustawień aplikacji. Klient ponosi
            odpowiedzialność za prawidłowość danych podanych przez Właściciela Licencji.
          </li>
          <li>
            <strong>4.7.&nbsp;</strong>Operator zastrzega sobie prawo do zablokowania dostępu Klientowi do Usług,
            jeżeli:
            <ol>
              Właściciel Licencji korzystał z Aplikacji oraz Usług Operatora naruszając przepisy Regulaminu; Użytkownik
              Aplikacji w ramach Konta Klienta korzystał z Aplikacji oraz Usług Operatora naruszając przepisy
              Regulaminu; Klient nie dokona płatności w terminie.
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <strong>5.&nbsp;Rejestracja Konta Użytkownika przez Klienta</strong>
        <ol>
          <li>
            <strong>5.1.&nbsp;</strong>Właściciel Licencji w przypadku, gdy Klient posiada Licencję wieloosobową może
            dodać wynikającą z Licencji liczbę Użytkowników.
          </li>
          <li>
            <strong>5.2.&nbsp;</strong>
            W tym celu w dedykowanym formularzu tworzenia użytkownika dostępnym w Aplikacji, Właściciel Licencji
            wprowadza następujące dane Użytkownika:
            <ol>
              <li>imię i nazwisko Użytkownika Konta Użytkownika,</li>
              <li>adres poczty elektronicznej Konta Użytkownika,</li>
              <li>nr telefonu Użytkownika;</li>
            </ol>
          </li>
          <li>
            <strong>5.3.&nbsp;</strong>
            Użytkownik otrzymuje na podany numer telefonu wiadomość sms z tymczasowym hasłem do Konta Użytkownika oraz
            email potwierdzający założenie konta. Przy pierwszym logowaniu Użytkownik akceptuje Regulamin Aplikacji i
            dokonuje zmiany hasła dostępu do Konta Użytkownika.
          </li>
        </ol>
      </li>
      <li>
        <strong>6.&nbsp;Zasady korzystania z aplikacji i odpowiedzialność Użytkowników</strong>
        <ol>
          <li>
            <strong>6.1.&nbsp;</strong>
            Właściciel Licencji zapewnia Operatora, iż każdy zarejestrowany w ramach Konta Klienta Użytkownik będzie
            przestrzegał zasad opisanych w tym rozdziale.
          </li>
          <li>
            <strong>6.2.&nbsp;</strong>
            Użytkownik zobowiązuje się do: korzystania Aplikacji w sposób zgodny z przeznaczeniem, korzystania z
            Aplikacji w sposób zgodny z przepisami prawa obowiązujących przepisów dotyczących ochrony danych osobowych
            oraz do przetwarzania danych właścicieli zwierząt jedynie w celach związanych z wykonywaniem swoich
            obowiązków zoofizjoterapeuty, zachowania poufności i nieujawniania danych właścicieli zwierząt osobom
            trzecim, chyba że jest to wymagane przez przepisy prawa lub uzgodnione z właścicielem zwierzęcia.
            niepodejmowania działań wkraczających w kompetencję Operatora lub mających na celu ingerencję w strukturę
            Aplikacji bądź wejście w posiadanie informacji nieprzeznaczonych dla Klienta lub Użytkownika,
            niezamieszczania w Aplikacji treści godzących w zasady współżycia społecznego lub zabronionych przez
            przepisy obowiązującego prawa. Użytkownik ponosi pełną odpowiedzialność za przetwarzanie danych właścicieli
            zwierząt, w tym za zapewnienie ich odpowiedniego zabezpieczenia przed dostępem osób nieuprawnionych do Konta
            Klienta i Konta Użytkownika oraz za ich aktualizację i usunięcie w przypadku uzasadnionej prośby właściciela
            zwierzęcia. W przypadku naruszenia przepisów dotyczących ochrony danych osobowych przez Użytkownika,
            Aplikacji, Operator nie ponosi odpowiedzialności za szkody wynikłe z takiego naruszenia.
          </li>
        </ol>
      </li>
      <li>
        <strong>7.&nbsp;Wyłączenie odpowiedzialności</strong>
        <ol>
          <li>
            <strong>7.1.&nbsp;</strong>
            Aplikacja zawiera informacje o charakterze ogólnym. Nie ma na celu pośredniczyć w świadczeniu jakichkolwiek
            usług doradztwa profesjonalnego. Przed podjęciem czynności mających wpływ na sytuację finansową, działalność
            gospodarczą lub podjęciem innych czynności w ramach prowadzonej działalności gospodarczej Użytkownika,
            należy skontaktować się z profesjonalnym doradcą.
          </li>
          <li>
            <strong>7.2.&nbsp;</strong>Operator nie ponosi odpowiedzialności za:
            <ol>
              <li>
                <strong>7.2.1.&nbsp;</strong>
                linki zamieszczone przez Użytkowników w Aplikacji, szczególnie jeśli prowadzą do witryn, zasobów lub
                narzędzi utrzymywanych przez osoby trzecie,
              </li>
              <li>
                <strong>7.2.2.&nbsp;</strong>naruszenia bezpieczeństwa wynikające z działań Użytkownika, takich jak
                słabe hasła lub udostępnianie danych logowania osobom trzecim,
              </li>
              <li>
                <strong>7.2.3.&nbsp;</strong>za szkody wynikłe z nieprawidłowego korzystania przez Użytkownika z
                Aplikacji,
              </li>
              <li>
                <strong>7.2.4.&nbsp;</strong>dostęp do Internetu ani za transfer danych w ramach obsługiwanego przez
                urządzenie Użytkownika łącza internetowego,
              </li>
              <li>
                <strong>7.2.5.&nbsp;</strong>za problemy techniczne bądź ograniczenia techniczne w sprzęcie
                komputerowym, z którego korzysta Użytkownik, a które uniemożliwiają Użytkownikowi korzystanie z
                Aplikacji lub oferowanych za jej pośrednictwem Usług.
              </li>
            </ol>
          </li>
          <li>
            <strong>7.3.&nbsp;</strong>
            Operator nie ponosi odpowiedzialności z tytułu naruszenia praw osób trzecich oraz wyrządzenia jakiejkolwiek
            szkody osobom trzecim, na skutek oraz w związku z działaniami prowadzonymi przez jakiegokolwiek Użytkownika
            zarejestrowanego w ramach Konta Klienta, przy wykorzystaniu Aplikacji.
          </li>
          <li>
            <strong>7.4.&nbsp;</strong>
            Operator nie ponosi odpowiedzialności za niewykonanie lub nienależyte wykonanie Usług z przyczyn leżących po
            stronie osób trzecich (w tym operatorów sieci telekomunikacyjnych), ani spowodowanych zdarzeniem o
            charakterze siły wyższej, o zaistnieniu których niezwłocznie poinformuje Klienta.
          </li>
        </ol>
      </li>
      <li>
        <strong>8.&nbsp;Opłaty</strong>
        <ol>
          <li>
            <strong>8.1.&nbsp;</strong>
            Testowe korzystanie z Aplikacji podczas konfiguracji oraz Okresu Próbnego jest bezpłatne i służy ustaleniu
            przez Klienta, czy Aplikacja jest Klientowi przydatna i spełnia jego oczekiwania.
          </li>
          <li>
            <strong>8.2.&nbsp;</strong>
            Po Okresie Próbnym korzystanie z poszczególnych Usług świadczonych przez Operatora w ramach Aplikacji jest
            odpłatne, zgodnie z warunkami wybranej Licencji, Cenniku dostępnym na stronie internetowej Aplikacji
            https://[domena-klienta].fizjo.pro/marketplace i warunkami wskazanymi w niniejszym Regulaminie.
          </li>
          <li>
            <strong>8.3.&nbsp;</strong>
            Wszystkie opłaty są pobierane w okresach miesięcznych lub rocznych z góry i nie podlegają zwrotowi.
          </li>
          <li>
            <strong>8.4.&nbsp;</strong>
            Szczegółowe informacje na temat Opłat oraz zakresu świadczeń w ramach poszczególnych Licencji znajdują się w
            Cenniku.
          </li>
          <li>
            <strong>8.5.&nbsp;</strong>
            Wszelkie zmiany w Cenniku będą ogłaszane na Stronie Internetowej oraz będą obowiązywać Klienta w odniesieniu
            do danego Konta Klienta:
            <ol>
              <li>
                <strong>8.5.1.&nbsp;</strong>
                dopiero po powiadomieniu przez Operatora Klienta o zmianach dokonanych dla wybranej przez niego
                Licencji, co najmniej na 2 tygodnie przed upływem jego aktualnego Okresu Abonamentowego,
              </li>
              <li>
                <strong>8.5.2.&nbsp;</strong>o ile Właściciel Licencji nie złoży rezygnacji z wybranej Licencji, co
                najmniej w formie korespondencji e-mail na adres
                <a href="kontakt&commat;fizjo.pro" target="_blank">kontakt&commat;fizjo.pro</a>, wyraźnie wskazując, iż
                nie zgadza się na nowe warunki świadczenia Usługi.
              </li>
            </ol>
          </li>
          <li>
            <strong>8.6.&nbsp;</strong>
            Z upływem Okresu Próbnego, po otrzymaniu informacji o wybraniu Licencji i uzupełnieniu niezbędnych danych, o
            których mowa w niniejszym Regulaminie oraz realizacji płatności, Operator wystawi Klientowi fakturę VAT z
            tytułu Opłaty i w tym samym dniu doręczy ją za pomocą poczty elektronicznej na wskazany adres e-mail
            Klienta.
          </li>
          <li>
            <strong>8.7.&nbsp;</strong>Opłata jest pobierana automatycznie w pierwszym dniu po zakończeniu Okresu
            Próbnego lub Okresu Abonamentowego za pośrednictwem usługi STRIPE (<a
              href="https://stripe.com/"
              target="_blank"
              >https://stripe.com/</a
            >)
          </li>
          <li>
            <strong>8.8.&nbsp;</strong>Operator zapewnia, iż wskazany na fakturze numer konta bankowego został zgłoszony
            do elektronicznego rejestru w ramach tzw. „białej listy podatników VAT”, o którym mowa w ustawie o podatku
            od towarów i usług.
          </li>
          <li>
            <strong>8.9.&nbsp;</strong>Jeżeli Klient, nie złożył oświadczenia o rezygnacji z Usług, Umowa ulega
            wydłużeniu o kolejny Okres Abonamentowy zgodnie z wybraną Licencją, a Operator na początku kolejnego Okresu
            Abonamentowego pobierze opłatę aktualną dla wybranej Licencji, wystawi i doręczy kolejną fakturę.
          </li>
          <li>
            <strong>8.10.&nbsp;</strong>Pierwszy dzień następujący po upływie ostatniego dnia Okresu Próbnego oznacza
            dzień rozpoczęcia Okresu Abonamentowego, a kończy się on datą, która odpowiada początkowemu dniowi, w którym
            rozpoczął się Okres Abonamentowy, a gdyby takiego dnia w kolejnym miesiącu nie było – w ostatnim dniu danego
            miesiąca.
          </li>
          <li>
            <strong>8.11.&nbsp;</strong>Właściciel Licencji w Panelu Ustawień Konta ma dostęp do informacji w zakresie
            płatności. W trakcie Okresu Abonamentowego,
          </li>
          <li>
            <strong>8.12.&nbsp;</strong>Właściciel Licencji może wystąpić do Operatora o zmianę Licencji kontaktując się
            e-mail na adres <a href="mailto:kontakt@fizjo.pro" target="_blank">kontakt&commat;fizjo.pro</a>. W takim
            przypadku zmiana Licencji będzie skuteczna począwszy od dnia następującego po zakończeniu aktualnego Okresu
            Abonamentowego.
          </li>
        </ol>
      </li>
      <li>
        <strong>9.&nbsp;Blokada dostępu do aplikacji</strong>
        <ol>
          <li>
            <strong>9.1.&nbsp;</strong>
            Nieuiszczenie przez Klienta płatności w dodatkowym terminie 30 (trzydziestu) dni od dnia upływu terminu
            płatności faktury, pomimo poinformowania korespondencją e-mail Klienta przez Operatora o braku dokonania
            płatności, skutkować będzie automatycznym zablokowaniem dostępu dla każdego Użytkownika przypisanego do
            danego Konta Klienta.
          </li>
          <li>
            <strong>9.2.&nbsp;</strong>Odblokowanie dostępu będzie możliwe po potwierdzeniu dokonania przez Klienta
            wpłaty pełnej zaległej kwoty tytułem Opłaty.
          </li>
          <li>
            <strong>9.3.&nbsp;</strong>Brak wpłaty pełnej zaległej kwoty tytułem Opłaty skutkować będzie usunięciem
            Konta Klienta i rozwiązaniem Umowy.
          </li>
        </ol>
      </li>
      <li>
        <strong>10.&nbsp;Rezygnacja z Usług i rozwiązanie Umowy</strong>
        <ol>
          <li>
            <strong>10.1.&nbsp;</strong>Klient może w każdym momencie zrezygnować z Usług Operatora i korzystania z
            Aplikacji, składając oświadczenie o wypowiedzeniu Umowy w drodze wiadomości e-mail na adres
            <a href="mailto:kontakt@fizjo.pro" target="_blank">kontakt&commat;fizjo.pro</a>.
          </li>
          <li>
            <strong>10.2.&nbsp;</strong>Jeżeli Klient, złożył oświadczenie o rezygnacji z Usług, Umowa ulega rozwiązaniu
            z dniem zakończenia aktualnego Okresu Abonamentowego, w którym złożono Operatorowi rezygnację. Do tego czasu
            Klient może korzystać z Aplikacji. Klientowi nie przysługuje zwrot Opłaty za niewykorzystany Okres
            Abonamentowy.
          </li>
          <li>
            <strong>10.3.&nbsp;</strong>Klientowi przysługuje prawo do otrzymania w formie elektronicznej danych
            wprowadzonych do systemu zgodnie z zasadami powierzenia przetwarzania danych osobowych, określonymi w
            Regulaminie.
          </li>
          <li>
            <strong>10.4.&nbsp;</strong>Właściciel Licencji w każdej chwili ma możliwość usunięcia:
            <ol>
              <li>
                <strong>10.4.1.&nbsp;</strong>
                Konta Użytkownika powiązanego z Kontem Klienta, przy czym wszystkie informacje związane z kontem
                Użytkownika zostaną usunięte z wyjatkiem informacji dotyczących procesów teraputycznych zwierząt;
              </li>
              <li>
                <strong>10.4.2.&nbsp;</strong>Konta Klienta, przy czym w takim przypadku wszystkie informacje
                umieszczone w Aplikacji powiązane z Kontem Klienta również zostaną usunięte, z wyłączeniem informacji
                dotyczących procesów teraputycznych zwierząt.
              </li>
            </ol>
          </li>
          <li>
            <strong>10.5.&nbsp;</strong>Usunięcie Konta Użytkownika przez Właściciela Licencji nie wpływa na obniżenie
            Opłaty i nie stanowi automatycznej zmiany wybranej Licencji.
          </li>
        </ol>
      </li>
      <li>
        <strong>11.&nbsp;Przerwy techniczne i dostępność usługi</strong>
        <ol>
          <li>
            <strong>11.1.&nbsp;</strong>
            Operator zapewnia możliwość korzystania z Aplikacji wszystkim zarejestrowanym Użytkownikom, dokładając
            należytej staranności, aby Usługa była dostępna na jak najwyższym poziomie.
          </li>
          <li>
            <strong>11.2.&nbsp;</strong>Operator jest uprawniony do czasowej przerwy w działaniu Aplikacji i
            świadczonych przez niego Usług z przyczyn technicznych, o której będzie informował Użytkowników z możliwie
            jak największym wyprzedzeniem.
          </li>
          <li>
            <strong>11.3.&nbsp;</strong>Operator dołoży najwyższej staranności, aby przerwy techniczne miały miejsce w
            godzinach nocnych i trwały możliwie najkrócej.
          </li>
          <li>
            <strong>11.4.&nbsp;</strong>W przypadku przerwy technicznej trwającej ponad 24h Klient ma prawo do
            przedłużenia ważności Okresu Abonamentowego, podczas którego miała miejsce przerwa techniczna, o tyle dni,
            ile trwała przerwa techniczna.
          </li>
          <li>
            <strong>11.5.&nbsp;</strong>Pomimo podejmowania przez Operatora największych starań, w kwestii zapewnienia
            dokładności i aktualności Aplikacji, mogą pojawić się niezamierzone przez Operatora błędy, które Użytkownik,
            po ich wykryciu, proszony jest zgłaszać Operatorowi.
          </li>
          <li>
            <strong>11.6.&nbsp;</strong>Operator podejmuje działania w celu wyeliminowania wszelkich pojawiających się
            problemów w oparciu o czasy reakcji. Czas reakcji to maksymalny czas, jaki może upłynąć od momentu
            zgłoszenia problemu przez Użytkownika pocztą elektroniczną do zakwalifikowania problemu.
          </li>
        </ol>
      </li>
      <li>
        <strong>12.&nbsp;Ochrona danych osobowych</strong>
        <ol>
          <li>
            <strong>12.1.&nbsp;</strong>
            Wszelkie dane osobowe, które zostaną podane przez Właściciela Licencji lub bezpośrednio przez Użytkownika,
            lub zebrane przez Operatora w odniesieniu do Klienta lub Użytkownika, w związku ze świadczeniem Usługi, będą
            przetwarzane w sposób zgodny z przepisami prawa, w tym zgodnie z Rozporządzeniem Parlamentu Europejskiego i
            Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem
            danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE, zwanego
            dalej „RODO”.
          </li>
          <li>
            <strong>12.2.&nbsp;</strong>Operator informuje, że świadczenie Usługi wymaga podania i przetwarzania danych
            osobowych, a bez ich przetwarzania świadczenie Usług przez Operatora nie będzie możliwe.
          </li>
          <li>
            <strong>12.3.&nbsp;</strong>Informacja o przetwarzania danych osobowych przez Operatora została opisana w
            Polityce prywatności dostępnej pod adresem
            <a href="https://fizjo.pro/privace-policy" target="_blank">https://fizjo.pro/privace-policy</a>, która jest
            udostępniana Właścicielowi Licencji i Użytkownikowi Aplikacji przy zakładaniu odpowiednio Konta Klienta i
            Konta Użytkownika.
          </li>
          <li>
            <strong>12.4.&nbsp;</strong>W celu świadczenia Usług w ramach Aplikacji, Klient – jako administrator danych
            osobowych Użytkowników Aplikacji - powierza Operatorowi – jako podmiotowi przetwarzającemu - przetwarzanie
            danych osobowych (przekazanych przy rejestracji oraz wprowadzonych do Aplikacji podczas świadczenia Usług) w
            zakresie niezbędnym do świadczenia Usług, zgodnie z art. 28 RODO i na warunkach na zasadach opisanych w tym
            rozdziale Regulaminu, za pełną akceptacją Klienta. W przypadku jakichkolwiek uwag wymagany jest kontakt
            mailowy z Operatorem w celu ewentualnego zawarcia indywidualnej umowy w tym zakresie.
          </li>
          <li>
            <strong>12.5.&nbsp;</strong>W celu prawidłowego świadczenia Usługi Klient powierza Operatorowi przetwarzanie
            danych osobowych pracowników, współpracowników oraz właścicieli zwierząt, zamieszczonych w Aplikacji przez
            Użytkowników w ramach Konta Klienta w zakresie: imienia, nazwiska, danych kontaktowych oraz informacji o
            przebiegu rehabilitacji zwierząt.
          </li>
          <li>
            <strong>12.6.&nbsp;</strong>Klient oświadcza, że powierzone dane osobowe zgromadził zgodnie z obowiązującymi
            przepisami prawa w związku z wykonywaną przez siebie działalnością i powierza ich przetwarzanie na czas
            trwania Umowy.
          </li>
          <li>
            <strong>12.7.&nbsp;</strong>Operator przetwarza dane osobowe wyłącznie na polecenie Klienta, w związku z
            korzystaniem przez Klienta z Aplikacji i jest upoważniony do wykonywania następujących czynności
            przetwarzania powierzonych danych: pobierania, utrwalania, organizowania, porządkowania, przechowywania,
            adaptowania, przeglądania, udostępniania, usuwania. Klient może przekazać Operatorowi drogą mailową
            dodatkowe polecenia przetwarzania danych osobowych.
          </li>
          <li>
            <strong>12.8.&nbsp;</strong>Operator niezwłocznie informuje Klienta, jeżeli polecenia przekazane przez
            niego, w opinii Operatora naruszają RODO lub inne mające zastosowanie przepisy o ochronie danych osobowych.
          </li>
          <li>
            <strong>12.9.&nbsp;</strong>Operator w szczególności zobowiązuje się:
            <ol>
              <li>
                <strong>12.9.1.&nbsp;</strong>przetwarzać dane osobowe wyłącznie w zakresie i celu określonym i
                przewidzianym w Umowie i tylko przez okres obowiązywania Umowy, chyba że obowiązek przetwarzania wynika
                z przepisów prawa, Umowy lub decyzji Klienta,
              </li>
              <li>
                <strong>12.9.2.&nbsp;</strong>przestrzegać warunków korzystania z usług innego podmiotu przetwarzającego
                w odniesieniu do przetwarzania danych osobowych, o których mowa w art. 28 ust. 2 i 4 RODO,
              </li>
              <li>
                <strong>12.9.3.&nbsp;</strong>udostępniać klientowi wszelkie informacje niezbędne do wykazania
                spełnienia obowiązków Klienta jako podmiotu przetwarzającego, wynikających z RODO i tego rozdziału
                Regulaminu,
              </li>
              <li>
                <strong>12.9.4.&nbsp;</strong>
                powiadomić Klienta o stwierdzeniu naruszenia ochrony danych osobowych bez zbędnej zwłoki, nie później
                niż ciągu 48 godzin od momentu stwierdzenia takiego naruszenia; powiadomienie zostanie przesłane na
                adres poczty elektronicznej wskazany przez Klienta,
              </li>
              <li>
                <strong>12.9.5.&nbsp;</strong>dokonać zwrotu powierzonych danych po zakończeniu przetwarzania danych
                osobowych.
              </li>
            </ol>
          </li>
          <li>
            <strong>12.10.&nbsp;</strong>Operator oświadcza, że:
            <ol>
              <li>
                <strong>12.10.1.&nbsp;</strong>
                wdrożył odpowiednie środki techniczne i organizacyjne, przy uwzględnieniu stanu wiedzy technicznej, aby
                zapewnić zgodność przetwarzania z RODO, w tym stopień bezpieczeństwa przetwarzania odpowiadający ryzyku
                naruszenia praw lub wolności osób, których dane dotyczą,
              </li>
              <li>
                <strong>12.10.2.&nbsp;</strong>
                urządzenia i systemy informatyczne służące do przetwarzania danych osobowych zapewniają poziom
                bezpieczeństwa odpowiedni do poziomu ryzyka,
              </li>
              <li>
                <strong>12.10.3.&nbsp;</strong>nadaje upoważnienia do przetwarzania danych osobowych wszystkim osobom,
                które będą je przetwarzały w związku z realizacją Umowy,
              </li>
              <li>
                <strong>12.10.4.&nbsp;</strong>udziela dostępu do danych osobowych wyłącznie osobom, które zobowiązały
                się do zachowania poufności i wyłącznie na zasadzie ograniczonego dostępu,
              </li>
              <li>
                <strong>12.10.5.&nbsp;</strong>w związku ze zobowiązaniem do zachowania w tajemnicy powierzonych danych,
                nie będą one wykorzystywane, ujawniane ani udostępniane bez pisemnej zgody Klienta w innym celu niż
                wykonanie Umowy, chyba że konieczność ujawnienia posiadanych informacji wynika z obowiązujących
                przepisów prawa.
              </li>
            </ol>
          </li>
          <li>
            <strong>12.11.&nbsp;</strong>Operator w miarę możliwości, poprzez odpowiednie środki techniczne i
            organizacyjne pomaga Klientowi w niezbędnym zakresie wywiązywać się z obowiązku odpowiadania na żądania
            osoby, której dotyczą dane osobowe (w zakresie wykonywania jej praw określonych w rozdziale III RODO) oraz
            wywiązywania się z obowiązków określonych w art. 32-36 RODO.
          </li>
          <li>
            <strong>12.12.&nbsp;</strong>
            Operator nie może powierzyć przetwarzania danych osobowych, innym podmiotom, bez uzyskania uprzedniej,
            pisemnej zgody Klienta, z wyłączeniem niżej wymienionych podmiotów:
            <table>
              <thead>
                <tr>
                  <th>Nazwa</th>
                  <th>Informacja o usłudze</th>
                  <th>Wpływ na przetwarzanie danych osobowych</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>IDE – Grzegorz Madajczak (Regon: 364137895)</td>
                  <td>Zapewnienie nieprzerwanego dostępu do Usługi w Aplikacji</td>
                  <td>Dostęp do danych przetwarzanych w Aplikacji</td>
                </tr>
                <tr>
                  <td>Google Workspace</td>
                  <td>Dostawca usługi poczty email, przestrzeń na dysku sieciowym na potrzeby Operatora</td>
                  <td>Możliwe przetwarzanie dokumentów z danymi osobowymi klientów fizjo.pro</td>
                </tr>
                <tr>
                  <td>Sendgrid <a href="https://sendgrid.com" target="_blank">https://sendgrid.com</a></td>
                  <td>
                    Dostawca usługi wysyłania email do użytkowników aplikacji, użytkowników końcowych (właścicieli
                    zwierząt)
                  </td>
                  <td>
                    Wysyła wiadomości do właścicieli zwierząt współpracowników zoofizjoterapeutów. Przetwarza adresy
                    email i imiona i nazwiska osób subskrybujących newsletter.
                  </td>
                </tr>

                <tr>
                  <td>
                    Google Cloud Platform
                    <a href="https://cloud.google.com" target="_blank">https://cloud.google.com</a>
                  </td>
                  <td>Dostawca usługi chmury obliczeniowej</td>
                  <td>
                    Przechowywanie danych Użytkowników Aplikacji (zoofizjoterapeutów), które mogą zawierać dane osobowe
                    właścicieli zwierząt
                  </td>
                </tr>
                <tr>
                  <td>Atlas Mongo <a href="https://www.mongodb.com" target="_blank">https://www.mongodb.com</a></td>
                  <td>Dostawca usługi bazodanowej</td>
                  <td>Przechowywanie danych</td>
                </tr>
                <tr>
                  <td>Bunny.net <a href="https://bunny.net/" target="_blank">https://bunny.net/</a></td>
                  <td>Usługa streamingu audio/video</td>
                  <td>Przechowywanie plików audio/video Użytkowników Aplikacji</td>
                </tr>
                <tr>
                  <td>Stripe <a href="https://stripe.com" target="_blank">https://stripe.com</a></td>
                  <td>Obsługa finansowa aplikacji</td>
                  <td>Przetwarza dane osobowe oraz dane finansowe Użytkowników Aplikacji</td>
                </tr>
                <tr>
                  <td>Betterstack <a href="https://betterstack.com" target="_blank">https://betterstack.com</a></td>
                  <td>Analiza logów aplikacji</td>
                  <td>Może wyświetlać dane użytkowników końcowych oraz właścicieli zwierząt</td>
                </tr>
                <tr>
                  <td>Sentry <a href="https://sentry.io" target="_blank">https://sentry.io</a></td>
                  <td>Gromadzenie logów dotyczących błędów w aplikacji</td>
                  <td>Może wyświetlać dane użytkowników końcowych oraz właścicieli zwierząt</td>
                </tr>
              </tbody>
            </table>
          </li>
          <li>
            <strong>12.13.&nbsp;</strong>Operator poinformuje Klienta na piśmie o każdej zamierzonej zmianie na liście
            dalszych podmiotów przetwarzających. Operator zapewnia, że będzie korzystał wyłącznie z usług takich
            dalszych podmiotów przetwarzających, które zapewniają wystarczające gwarancje wdrożenia odpowiednich środków
            technicznych i organizacyjnych, by przetwarzanie spełniało wymogi RODO, a także chroniło prawa osób, których
            dotyczą dane osobowe. Operator odpowiada za działania i zaniechania dalszych podmiotów przetwarzających tak
            jak za własne działania i zaniechania.
          </li>
          <li>
            <strong>12.14.&nbsp;</strong>Jeśli Operator otrzyma od Klienta zgodę na dalsze powierzenie danych osobowych
            jest obowiązany zapewnić w umowie z dalszym podmiotem przetwarzającym możliwość przeprowadzania przez
            Klienta kontroli (audytu) zgodności przetwarzania danych osobowych przez dalszy podmiot przetwarzający.
          </li>
          <li>
            <strong>12.15.&nbsp;</strong>Operator niezwłocznie nie później niż w terminie 7 dni poinformuje klienta o
            wszelkich czynnościach, w szczególności kontrolnych i skargowych, prowadzonych przez organ nadzorczy z
            zakresu powierzonych danych.
          </li>
          <li>
            <strong>12.16.&nbsp;</strong>
            Klient zgodnie z art. 28 ust. 3 pkt h) RODO ma prawo kontroli (audytu) sposobu wypełnienia przez Operatora
            obowiązków umownych, w szczególności sprawdzenia czy środki zastosowane przez Operatora przy przetwarzaniu i
            zabezpieczeniu powierzonych danych osobowych spełniają postanowienia Umowy.
          </li>
          <li>
            <strong>12.17.&nbsp;</strong>Klient powiadomi Operatora o zamiarze przeprowadzenia przedmiotowej kontroli z
            wyprzedzeniem, nie krótszym niż 14 dni. Kontrola może być przeprowadzana przez jedną lub kilka osób, nie
            może zakłócać codziennych czynności Operatora, odbywać się musi w normalnych godzinach pracy Operatora. W
            danym roku kalendarzowym może odbyć się wyłącznie jedna Kontrola. Wszelkie koszty związane z
            przeprowadzeniem kontroli ponosi Klient.
          </li>
          <li>
            <strong>12.18.&nbsp;</strong>Po przeprowadzonej kontroli (audycie) przedstawiciel Klienta sporządza protokół
            pokontrolny, który podpisuje przedstawiciel Klienta i Operator.
          </li>
          <li>
            <strong>12.19.&nbsp;</strong>Operator zobowiązuje się do usunięcia uchybień stwierdzonych podczas kontroli w
            terminie wskazanym przez Klienta w uzgodnieniu z Operatorem.
          </li>
          <li>
            <strong>12.20.&nbsp;</strong>Operator odpowiada za szkody, jakie powstaną u Klienta, osób, których dane
            osobowe dotyczą, lub innych osób trzecich w wyniku niezgodnego z niniejszym rozdziałem Regulaminu lub
            przepisami prawa przetwarzania przez Operatora danych osobowych, a w szczególności w związku z
            udostępnianiem danych osobowych osobom nieupoważnionym.
          </li>
          <li>
            <strong>12.21.&nbsp;</strong>Klient może rozwiązać Umowę ze skutkiem natychmiastowym, gdy:
            <ol>
              <li>
                <strong>12.21.1.&nbsp;</strong>
                organ nadzoru nad przestrzeganiem zasad przetwarzania danych osobowych stwierdzi, że Operator lub dalszy
                podmiot przetwarzający nie przestrzega zasad przetwarzania danych osobowych,
              </li>
              <li>
                <strong>12.21.2.&nbsp;</strong>
                prawomocne orzeczenie sądu powszechnego wykaże, że Operator lub dalszy podmiot przetwarzający nie
                przestrzega zasad przetwarzania danych osobowych,
              </li>
              <li>
                <strong>12.21.3.&nbsp;</strong>
                Klient, w wyniku przeprowadzenia kontroli (audytu), stwierdzi, że Operator narusza postanowienia
                niniejszego rozdziału Regulaminu lub obowiązujących przepisów prawa, bądź też Operator nie zastosuje się
                do zaleceń pokontrolnych.
              </li>
            </ol>
          </li>
          <li>
            <strong>12.22.&nbsp;</strong>Po zakończeniu świadczenia przez Operatora Usługi w ramach Umową, Operator
            niezwłocznie, w terminie nie dłuższym niż 14 dni roboczych od dnia zakończenia Umowy przekaże dane osobowe
            zebrane w Aplikacji oraz zniszczy wszystkie pozostające w jego posiadaniu kopie danych osobowych,
            zgromadzone na wszelkich technologicznie możliwych nośnikach, za wyjątkiem sytuacji, gdy pozyska inną
            podstawę prawną do ich przetwarzania. Wszelkie zmiany do Umowy powierzenia wymagają formy pisemnej, pod
            rygorem nieważności.
          </li>
        </ol>
      </li>
      <li>
        <strong>13.&nbsp;Reklamacje</strong>
        <ol>
          <li>
            <strong>13.1.&nbsp;</strong>
            Wszelkie reklamacje związane ze świadczeniem Usług przez Operatora oraz pytania dotyczące użytkowania
            Aplikacji należy przesyłać mailowo na adres: kontakt&commat;fizjo.pro.
          </li>
          <li>
            <strong>13.2.&nbsp;</strong>
            Reklamacja powinna zawierać w swej treści: imię i nazwisko – w przypadku osoby fizycznej lub firmę – w
            przypadku osoby prawnej lub jednostki organizacyjnej niebędącej osobą prawną, której ustawa przyznaje
            osobowość prawną oraz Login Użytkownika, jak również dokładny opis i powód reklamacji, wraz z oczekiwanym
            przez Użytkownika sposobem jej rozpatrzenia przez Operatora.
          </li>
          <li>
            <strong>13.3.&nbsp;</strong>
            Reklamacje będące następstwem niestosowania się do treści Regulaminu nie będą przez Operatora rozpatrywane.
          </li>
          <li>
            <strong>13.4.&nbsp;</strong>Operator rozpatruje reklamację w ciągu 14 dni roboczych od dnia jej otrzymania
            oraz informuje niezwłocznie Użytkownika, za pomocą poczty elektronicznej, o sposobie jej rozpatrzenia. W
            sytuacji, gdy podane w reklamacji dane lub informacje wymagają uzupełnienia Operator zwraca się, przed
            rozpatrzeniem reklamacji, do składającego reklamację o jej uzupełnienie. Czas udzielania dodatkowych
            wyjaśnień przez Użytkownika przedłuża odpowiednio okres rozpatrywania reklamacji.
          </li>
          <li>
            <strong>13.5.&nbsp;</strong>Wszelkie inne powiadomienia, uwagi i pytania dotyczące funkcjonowania Aplikacji
            można kierować pocztą elektroniczną na adres
            <a href="mailto:kontakt@fizjo.pro" target="_blank">kontakt&commat;fizjo.pro</a>.
          </li>
        </ol>
      </li>
      <li>
        <strong>14.&nbsp;Postanowienia końcowe</strong>
        <ol>
          <li>
            <strong>14.1.&nbsp;</strong>
            Regulaminu jest dostępna w każdym czasie, za pośrednictwem odsyłacza zamieszczonego na Stronie Internetowej
            (<a href="https://fizjo.pro/terms" target="_blank">https://fizjo.pro/terms</a>), w Aplikacji lub poprzez
            kontakt na adres <a href="mailto:kontakt@fizjo.pro" target="_blank">kontakt&commat;fizjo.pro</a>.
          </li>
          <li>
            <strong>14.2.&nbsp;</strong>W kwestiach nieuregulowanych Regulaminem lub odrębnie umową zawartą pomiędzy
            Operatorem a Klientem w formie pisemnej (pod rygorem nieważności), stosuje się przepisy kodeksu cywilnego
            oraz innych ustaw.
          </li>
          <li>
            <strong>14.3.&nbsp;</strong>Operator ma prawo w każdym czasie zmienić Regulamin, przy czym nowa treść
            Regulaminu obowiązuje dopiero po publikacji i powiadomieniu Klienta o zmianie Regulaminu, chyba że inaczej
            wskazano w zmodyfikowanym Regulaminie.
          </li>
          <li>
            <strong>14.4.&nbsp;</strong>W przypadku, gdy Właściciel Licencji nie zaakceptuje nowej treści Regulaminu,
            Umowa ulega rozwiązaniu z upływem ostatniego dnia aktualnego Okresu Abonamentowego.
          </li>
          <li>
            <strong>14.5.&nbsp;</strong>Gdyby którekolwiek z postanowień Regulaminu było lub miało stać się nieważne lub
            bezskuteczne, w dowolnym systemie prawnym, pozostała część Regulaminu pozostaje ważna i nienaruszona. Strony
            zastąpią nieważne lub bezskuteczne postanowienie innym, które możliwie najwierniej oddaje zamierzony cel.
            Odpowiednio dotyczy to także ewentualnych luk w Regulaminie.
          </li>
          <li>
            <strong>14.6.&nbsp;</strong>Gdyby którekolwiek z postanowień Regulaminu było lub miało stać się nieważne lub
            bezskuteczne, w jednym lub kilku systemach prawnych, wszystkie postanowienia Regulaminu zachowują ważność w
            każdym innym systemie prawnym.
          </li>
          <li>
            <strong>14.7.&nbsp;</strong>Wszelkie powstałe spory Strony postanawiają w pierwszej kolejności rozwiązać w
            trybie polubownego załatwienia sprawy, przed właściwym sądem polubownym.
          </li>
          <li>
            <strong>14.8.&nbsp;</strong>Jeśli polubowne załatwienie sprawy okaże się niemożliwe, spory wynikające z
            Umowy zostaną poddane pod rozstrzygnięcie sądu powszechnego, właściwego ze względu na siedzibę Operatora.
          </li>
        </ol>
      </li>
    </ol>
  </p-card>
</div>
