import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { AccountActions } from '@fizjo-pro/data-account';
import { ApiAuthService, authActions, CanActivateDto } from '@fizjo-pro/data-auth';
import { Store } from '@ngrx/store';
import jwtDecode from 'jwt-decode';
import { catchError, EMPTY, Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  authService = inject(ApiAuthService),
  store = inject(Store),
  router = inject(Router)
): Observable<boolean> =>
  authService.authControllerCanActivate({ path: route.url.map((segment: UrlSegment) => segment.path).join('_') }).pipe(
    tap((canActivate: CanActivateDto) => {
      const shouldChangePassword: boolean = jwtDecode<{ forcePasswordChange: boolean }>(
        canActivate.token
      ).forcePasswordChange;

      const shouldAcceptTerms = !jwtDecode<{ termsAcceptedDate: Date | null }>(canActivate.token).termsAcceptedDate;

      if (shouldChangePassword || shouldAcceptTerms) {
        router.navigate(['/auth/init']);
      }
      store.dispatch(authActions.setAuthenticated({ tokenDto: { token: canActivate.token } }));
      store.dispatch(AccountActions.load());
    }),
    map((canActivate: CanActivateDto) => canActivate.canActivate),
    catchError(() => {
      router.navigate(['/auth']);
      store.dispatch(authActions.setUnauthenticated({ source: 'auth-guard' }));

      return EMPTY;
    })
  );
