import { Routes } from '@angular/router';
import { appointmentProviders, AppointmentResolver } from '@fizjo-pro/data-appointment';
import { calendarProviders, calendarResolver } from '@fizjo-pro/data-calendar';
import { ownerProviders, OwnerResolver } from '@fizjo-pro/data-owner';
import { AllPatientsResolver, patientProviders } from '@fizjo-pro/data-patient';
import { ProcedureTemplateResolver, templatesProviders } from '@fizjo-pro/data-procedure-template';
import { PrivacyPolicyComponent, TermsComponent } from '@fizjo-pro/feat-auth';
import { appUsersProviders, AppUsersResolver } from '@fizjo-pro/settings/data-user';
import { settingsRoutes } from '@fizjo-pro/settings/feat-settings';
import { authGuard, AuthService } from '@fizjo-pro/util-auth';

import { MainComponent } from './main.component';

export const appRoutes: Routes = [
  {
    loadChildren: () => import('@fizjo-pro/feat-auth').then(m => m.authRoutes),
    path: 'auth',
    providers: [AuthService],
  },
  {
    loadChildren: () => import('@fizjo-pro/feat-auth').then(m => m.authRoutes),
    path: 'auth',
    providers: [AuthService],
  },
  {
    component: TermsComponent,
    path: 'terms',
  },
  {
    component: PrivacyPolicyComponent,
    path: 'privacy-policy',
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [authGuard],
    children: [
      {
        canActivate: [authGuard],
        loadChildren: () => import('@fizjo-pro/feat-products').then(m => m.productsRoutes),
        path: 'products',
      },
      {
        canActivate: [authGuard],
        loadComponent: () => import('@fizjo-pro/feat-calendar').then(m => m.CalendarComponent),
        path: '',
        providers: [
          ...appointmentProviders,
          ...patientProviders,
          ...ownerProviders,
          ...calendarProviders,
          ...templatesProviders,
          ...appUsersProviders,
        ],
        resolve: {
          appointments: AppointmentResolver,
          appUsers: AppUsersResolver,
          calendar: calendarResolver,
          owners: OwnerResolver,
          patient: AllPatientsResolver,
          templates: ProcedureTemplateResolver,
        },
      },
      {
        canActivate: [authGuard],
        loadChildren: () => import('@fizjo-pro/feat-appointment').then(m => m.appointmentsRoutes),
        path: 'appointments',
        providers: [
          ...appointmentProviders,
          ...patientProviders,
          ...templatesProviders,
          ...calendarProviders,
          ...ownerProviders,
        ],
        resolve: {
          patients: AllPatientsResolver,
          templates: ProcedureTemplateResolver,
          owners: OwnerResolver,
        },
      },
      {
        canActivate: [authGuard],
        loadChildren: () => import('@fizjo-pro/feat-patient').then(m => m.PatientRoutes),
        path: 'patients',
      },
      {
        canActivate: [authGuard],
        loadChildren: () => import('@fizjo-pro/feat-owner').then(m => m.ownerRoutes),
        path: 'owners',
      },
      {
        canActivate: [authGuard],
        children: settingsRoutes,
        loadComponent: () => import('@fizjo-pro/settings/feat-settings').then(m => m.SettingsComponent),
        path: 'settings',
      },
      {
        canActivate: [authGuard],
        loadChildren: () => import('@fizjo-pro/settings/feat-app-info').then(m => m.appInfoRoutes),
        path: 'app-info',
      },
      {
        canActivate: [authGuard],
        loadChildren: () => import('@fizjo-pro/feat-procedure').then(m => m.procedureRoutes),
        path: 'procedures',
        providers: [...templatesProviders, ...patientProviders, ...appointmentProviders, ...calendarProviders],
        resolve: {
          appointments: AppointmentResolver,
          patient: AllPatientsResolver,
          templates: ProcedureTemplateResolver,
        },
      },
      {
        canActivate: [authGuard],
        loadChildren: () => import('@fizjo-pro/feat-general').then(m => m.generalRoutes),
        path: 'appointment',
      },
      {
        loadComponent: () => import('@fizjo-pro/feat-accept-gdpr').then(m => m.AcceptGdprComponent),
        path: 'gdpr',
      },
      {
        canActivate: [authGuard],
        loadComponent: () => import('@fizjo-pro/feat-messages').then(m => m.MessagesComponent),
        path: 'messages',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
