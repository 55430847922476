import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppDomainService, X_TENANT_ID } from '@fizjo-pro/shared/util-app-domain';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { MessageService } from 'primeng/api';
import { Observable, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppointmentDto, AppointmentsListItemDto } from '../../api/models';
import { AppointmentService } from '../../api/services';
import { appointmentActions } from '../appointment.actions';

@Injectable()
export class AppointmentEffects {
  #router: Router = inject(Router);
  #translateService: TranslateService = inject(TranslateService);
  #actions$: Actions = inject(Actions);
  #appointmentService: AppointmentService = inject(AppointmentService);
  #messageService: MessageService = inject(MessageService);
  #appDomainService: AppDomainService = inject(AppDomainService);

  public fetch$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(appointmentActions.fetch),
      switchMap(() =>
        this.#appointmentService.appointmentsControllerReadAll({
          from: DateTime.now().startOf('month').toISO() || '',
          to: DateTime.now().endOf('day').toISO() || '',
        })
      ),
      map((appointments: AppointmentsListItemDto[]) => appointmentActions.fetchSuccess({ appointments }))
    );
  });
  public moveAppointmentInCalendar$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(appointmentActions.move),
      switchMap(action =>
        this.#appointmentService.appointmentsControllerMove({
          appointmentId: action.appointmentId,
          body: action.payload,
        })
      ),
      map(appointmentDto => appointmentActions.moveSuccess({ appointmentDto }))
    );
  });

  public fetchAllForPatient$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(appointmentActions.fetchAllForPatient),
      switchMap(action =>
        this.#appointmentService.appointmentsControllerReadAllForPatient({
          patientId: action.patientId,
        })
      ),
      map((appointments: AppointmentsListItemDto[]) => appointmentActions.fetchAllForPatientSuccess({ appointments }))
    );
  });
  public fetchRange$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(appointmentActions.fetchRange),
      switchMap(action =>
        this.#appointmentService.appointmentsControllerReadAll({
          from: DateTime.fromJSDate(action.from).toISO() || '',
          to: DateTime.fromJSDate(action.to).toISO() || '',
        })
      ),
      map((appointments: AppointmentsListItemDto[]) => appointmentActions.fetchSuccess({ appointments }))
    );
  });
  public fetchSingle$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(appointmentActions.fetchSingleAppointment),
      switchMap(action =>
        this.#appointmentService.appointmentsControllerReadOne({ appointmentId: action.appointmentId })
      ),
      map((appointmentDto: AppointmentDto) => appointmentActions.fetchSingleAppointmentSuccess({ appointmentDto }))
    );
  });
  public delete$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(appointmentActions.delete),
      switchMap(action => this.#deleteAppointment$(action.appointmentId)),
      map((appointmentId: string) => appointmentActions.deleteSuccess({ appointmentId }))
    );
  });
  public deleteSuccess$ = createEffect(
    () => {
      return this.#actions$.pipe(
        ofType(appointmentActions.deleteSuccess),
        switchMap(() => this.#translateService.get(['appointment.appointment', 'appointment.deleteSuccessMessage'])),
        tap((transl: Record<string, string>) => {
          this.#messageService.add({
            detail: transl['appointment.deleteSuccessMessage'],
            severity: 'success',
            summary: transl['appointment.appointment'],
          });
          this.#router.navigate(['/appointments']);
        })
      );
    },
    { dispatch: false }
  );
  public shareReport$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(appointmentActions.shareReport),
      switchMap(action =>
        this.#appointmentService.singleAppointmentControllerShareAppointmentReport({
          appointmentId: action.appointmentId,
          [X_TENANT_ID]: this.#appDomainService.tenantId,
          body: { emails: action.emails, message: action.message },
        })
      ),
      map(() => appointmentActions.shareReportSuccess())
    );
  });
  public shareReportSuccess$ = createEffect(
    () => {
      return this.#actions$.pipe(
        ofType(appointmentActions.shareReportSuccess),
        switchMap(() => this.#translateService.get(['appointment.shareSummary', 'appointment.shareSuccessMessage'])),
        tap((transl: Record<string, string>) => {
          this.#messageService.add({
            detail: transl['appointment.shareSuccessMessage'],
            severity: 'success',
            summary: transl['appointment.shareSummary'],
          });
        })
      );
    },
    { dispatch: false }
  );
  public updateNote$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(appointmentActions.updateNote),
      switchMap(action =>
        this.#appointmentService.appointmentsControllerUpdateNote({
          appointmentId: action.appointmentId,
          noteId: action.noteId,
          body: { note: action.note },
        })
      ),
      map((appointmentDto: AppointmentDto) => appointmentActions.updateNoteSuccess({ appointmentDto }))
    );
  });
  public updateNoteSuccess$ = createEffect(
    () => {
      return this.#actions$.pipe(
        ofType(appointmentActions.updateNoteSuccess),
        switchMap(() =>
          this.#translateService.get(['appointment.updateNoteSuccess', 'appointment.updateNoteSuccessMessage'])
        ),
        tap((transl: Record<string, string>) => {
          this.#messageService.add({
            severity: 'success',
            detail: transl['appointment.updateNoteSuccess'],
          });
        })
      );
    },
    { dispatch: false }
  );
  public deleteNote$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(appointmentActions.deleteNote),
      switchMap(action =>
        this.#appointmentService.appointmentsControllerDeleteNote({
          appointmentId: action.appointmentId,
          noteId: action.noteId,
        })
      ),
      map((appointmentDto: AppointmentDto) => appointmentActions.deleteNoteSuccess({ appointmentDto }))
    );
  });

  public deleteNoteSuccess$ = createEffect(
    () => {
      return this.#actions$.pipe(
        ofType(appointmentActions.deleteNoteSuccess),
        switchMap(() => this.#translateService.get(['appointment.deleteNoteSuccess'])),
        tap((transl: Record<string, string>) => {
          this.#messageService.add({
            severity: 'success',
            detail: transl['appointment.deleteNoteSuccess'],
          });
        })
      );
    },
    { dispatch: false }
  );

  #deleteAppointment$(appointmentId: string): Observable<string> {
    return this.#appointmentService
      .singleAppointmentControllerDeleteAppointment({ appointmentId })
      .pipe(map(() => appointmentId));
  }
}
