import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GdprContentDto } from './api/models/gdpr-content-dto';
import { GdprSettingsDto } from './api/models/gdpr-settings-dto';
import { GdprSettingsPayloadSingleDto } from './api/models/gdpr-settings-payload-single-dto';
import { OpenaiService } from './api/services/openai.service';
import { gdprActions } from './state/gdpr.actions';
import { selectGdprSettings } from './state/gdpr.selectors';

@Injectable()
export class GdprFacade {
  readonly #openAi: OpenaiService = inject(OpenaiService);
  public readonly gdprSettings$: Observable<GdprSettingsDto | null>;

  constructor(private store: Store) {
    this.gdprSettings$ = store.select(selectGdprSettings);
  }

  public update(payload: GdprSettingsPayloadSingleDto): void {
    this.store.dispatch(gdprActions.update({ payload }));
  }

  public generateGdprContent$(): Observable<string> {
    return this.#openAi
      .openAiControllerGenerate()
      .pipe(map((gdprContentDto: GdprContentDto) => gdprContentDto.content));
  }
}
