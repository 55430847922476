import { inject, Injectable } from '@angular/core';
import { AppDomainService, X_TENANT_ID } from '@fizjo-pro/shared/util-app-domain';
import { Store } from '@ngrx/store';
import jwtDecode from 'jwt-decode';
import { Observable, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

import { Permission } from './api/models/permission';
import { Role } from './api/models/role';
import { TokenDto } from './api/models/token-dto';
import { UserDto } from './api/models/user-dto';
import { AuthService } from './api/services/auth.service';
import { PasswordService } from './api/services/password.service';
import { authActions } from './state/auth.actions';
import {
  selectForcePasswordChange,
  selectIsAuthenticated,
  selectMyData,
  selectPermissions,
  selectRole,
  selectSessionTimeout,
  selectTermsAcceptedDate,
  selectUserReady,
} from './state/auth.selectors';

export interface UserDataFromTokenDto extends Request {
  tenantId: string;

  email: string;

  _id: string;

  role: Role;

  licenses: string[];

  permissions: Permission[];

  forcePasswordChange: boolean;
}

@Injectable()
export class AuthFacade {
  #authService: AuthService = inject(AuthService);
  #passwordService: PasswordService = inject(PasswordService);

  #appDomainService: AppDomainService = inject(AppDomainService);
  #store: Store = inject(Store);

  public authenticated$: Observable<boolean> = this.#store.select(selectIsAuthenticated);
  public userReady$: Observable<boolean> = this.#store.select(selectUserReady);
  public readonly me$: Observable<UserDto | null> = this.#store.select(selectMyData);
  public readonly role$: Observable<Role> = this.#store.select(selectRole);
  public readonly permissions$: Observable<Permission[]> = this.#store.select(selectPermissions);
  public sessionTimeout$: Observable<number> = this.#store.select(selectSessionTimeout);
  public shouldChangePassword$: Observable<boolean> = this.#store.select(selectForcePasswordChange);
  public shouldAcceptTerms$: Observable<boolean> = this.#store.select(selectTermsAcceptedDate).pipe(map(date => !date));

  public setAuthenticated(tokenDto: TokenDto): void {
    this.#store.dispatch(authActions.setAuthenticated({ tokenDto }));
  }

  public setUnauthenticated(source: string, uid?: string): void {
    this.#store.dispatch(authActions.setUnauthenticated({ uid, source }));
  }

  public resolveOwnData(): void {
    this.#store.dispatch(authActions.fetchOwnUser());
  }

  public requestMfa$(email: string): Observable<unknown> {
    return this.#authService.authControllerRequestMfa({ [X_TENANT_ID]: this.#appDomainService.tenantId, email });
  }

  public submitPasswordChange$(code: string, email: string, password: string): Observable<void> {
    return this.#passwordService.passwordControllerChangePasswordWithEmail({ body: { email, password, code } });
  }

  public requestVerificationCode$(email: string): Observable<Record<string, string>> {
    return this.#appDomainService
      .tenantId$()
      .pipe(
        switchMap(tenantId =>
          this.#passwordService.passwordControllerRequestMfaCode({ [X_TENANT_ID]: tenantId, email })
        )
      );
  }

  public changeForcedPassword$(code: string, password: string): Observable<TokenDto> {
    return this.#passwordService.passwordControllerForcePasswordChange({ body: { code, password } });
  }

  public storeRefreshToken(token: string): void {
    const { _id }: UserDataFromTokenDto = jwtDecode<UserDataFromTokenDto>(token);

    localStorage.setItem(_id, token);
  }
}
